<template>
  <div class="wallet">
    <div class="account_info">
      <div class="residual" @click="toCapitalAccount">
        <svg-icon icon-class="zhye_icon" />
        <span>账户余额</span>
        <p>{{ (balance / 100).toFixed(2) }}</p>
      </div>
      <div class="bank_card" @click="$router.push('bankCard')">
        <svg-icon icon-class="yhk_icon" />
        <span>银行卡</span>
        <p>{{ bankCard.length }}</p>
      </div>
    </div>
    <div class="tips"></div>
    <div class="point_card_box">
      <h5 class="point_card_num">积分卡（{{ bank.length }}）</h5>
      <div
        class="point_card"
        v-for="list in bank"
        :key="list.bankId"
        @click="
          $router.push({
            path: '/userData/integral',
            query: {
              bankId: list.bankId,
              name:
                list.bankId === '0' ? '正品汇平台积分' : `${list.bankName}积分`,
            },
          })
        "
      >
        <!-- @click="
          list.bankId === '0'
            ? $router.push({
                path: '/userData/integral',
                query: {
                  bankId: list.bankId,
                  name: '正品汇平台积分',
                },
              })
            : $router.push({
                path: '/pointsMall',
              })
        " -->
        <!-- @click="
          $router.push({
            path: '/userData/integral',
            query: {
              bankId: list.bankId,
              name:
                list.bankId === '0' ? '正品汇平台积分' : `${list.bankName}积分`,
            },
          })
        " -->
        <!-- <img src="@/assets/images/jb_icon.png" alt="" /> -->
        <div>
          <h2>
            {{ list.bankId === "0" ? "正品汇平台积分" : `${list.bankName}` }}
          </h2>
          <span>积分 {{ list.integral }} </span>
          <!-- <span class="details"
            >明细 <svg-icon icon-class="arrow_right2"
          /></span> -->
        </div>
        <button
          v-if="list.bankId === '0'"
          @click.stop="
            $router.push({
              path: '/userData/signIn',
              query: {
                automaticCheckIn: '1',
              },
            })
          "
        >
          每日签到
        </button>
        <!-- @click.stop="$router.push('/pointsMall')" -->
        <button
          v-else
          @click.stop="
            $store.state.App
              ? $router.push('/pointsMall')
              : Toast.fail('暂未开通')
          "
        >
          前往积分商城 <svg-icon icon-class="arrow_right2" />
        </button>
      </div>
      <p class="none" v-if="!bank.length">暂无内容</p>
    </div>

    <!-- <van-overlay
      :show="tipsShow"
      z-index="1000"
      class-name="sign_in_successfully_tips"
    >
      <div class="title">
        <img src="@/assets/images/popup_head.png" /><span
          class="close"
          @click="tipsShow = false"
        ></span>
      </div>
      <div class="content">
        <h3>签到成功，送您奖励</h3>
        <h2>+5积分</h2>
        <p>本周已累计签到<span>1</span>天</p>
        <p>周累计签到<span>4天/7天</span>可获得额外奖励</p>
        <van-button round color="#FB6E10" @click="tipsShow = false"
          >确认</van-button
        >
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "wallet",

  data() {
    return {
      tipsShow: false,
      balance: 0,
    };
  },
  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "bank",
      "bankCard",
      "electronicAcct",
    ]),
  },

  async created() {
    if (!this.isLogin) {
      this.$router.replace("/login");
      return;
    }
    await this.$store.dispatch("user/getCusBankFilePathList");
    await this.$store.dispatch("user/queryElectronicAcct");

    this.electronicAcct.forEach((e) => {
      this.balance +=
        e.expensingAmount +
        e.frozenSettledAmount +
        e.pendingAmount +
        e.settledAmount;
    });
  },

  methods: {
    async toCapitalAccount() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.user
        .queryApplications({
          object: {
            serviceType: 1,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          this.Toast.clear();
          if (res.data.data.status) {
            this.$router.push("wallet/capitalAccount");
          } else {
            Dialog.confirm({
              message: "需先开通资金账户\n是否去开通资金账户",
            })
              .then(() => {
                // on confirm
                this.$router.push("/promotion/promotionRegistration");
              })
              .catch(() => {
                // on cancel
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.Toast.clear();
        });
    },
    toPointsMall() {
      this.$store.state.App
        ? this.$router.push("/pointsMall")
        : this.Toast.fail("暂未开通");
    },
  },
};
</script>

<style lang="scss" scoped>
.wallet {
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
  .account_info {
    background: #467dff;
    color: #fff;
    display: flex;
    padding: 27px 0;
    border-radius: 0px 0px 15px 15px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .svg-icon {
        font-size: 32px;
      }
      span {
        margin: 8px 0 12px 0;
        line-height: 14px;
      }
      p {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
  .point_card_box {
    padding: 0 15px;
    .point_card_num {
      margin: 28px 0 4px 0;
      font-size: 16px;
      color: #333333;
    }
    .point_card {
      height: 94px;
      background: #fd5914;
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      align-items: center;
      color: #fff;
      background: url("../../../assets/images/point_card_bg.png") no-repeat 100%
        100%;
      background-size: cover;
      padding: 0 25px 0 10px;
      margin-top: 8px;

      div {
        margin-left: 10px;
        h2 {
          font-size: 21px;
          margin-bottom: 4px;
        }
        .details {
          line-height: 16px;
          text-decoration: underline;
          .svg-icon {
            font-size: 10px;
            vertical-align: unset;
          }
        }
      }
      button {
        padding: 7px 14px;
        background: #ffffff;
        border-radius: 22px;
        color: #fb9b32;
        border: none;
        .svg-icon {
          font-size: 12px;
        }
      }
    }
    .none {
      font-size: 12px;
      color: #999999;
      text-align: center;
      padding: 44px 0;
    }
  }
  // .sign_in_successfully_tips {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0 43px;
  //   background-color: rgba($color: #000000, $alpha: 0.48);

  //   .title {
  //     position: relative;
  //     width: 100%;
  //     max-width: 414px;
  //     img {
  //       width: 100%;
  //       display: block;
  //     }
  //     .close {
  //       position: absolute;
  //       right: 0;
  //       top: 0;
  //       width: 11.5%;
  //       height: 16%;
  //     }
  //   }

  //   .content {
  //     position: relative;
  //     top: -10px;
  //     background: #fff;
  //     width: 100%;
  //     max-width: 414px;
  //     text-align: center;
  //     padding: 0 15px 15px;
  //     border-radius: 0 0 20px 20px;
  //     h3 {
  //       font-size: 16px;
  //       color: #333333;
  //       line-height: 22px;
  //     }
  //     h2 {
  //       font-size: 32px;
  //       color: #fb6e10;
  //       line-height: 45px;
  //       margin: 25px 0 21px 0;
  //     }
  //     p {
  //       line-height: 20px;
  //       span {
  //         color: #fb4e10;
  //       }
  //     }
  //     p:nth-child(3) {
  //       color: #333333;
  //       margin-bottom: 4px;
  //     }
  //     p:nth-child(4) {
  //       color: #a8a8a8;
  //       margin-bottom: 20px;
  //     }
  //     .van-button {
  //       padding: 0 59px;
  //       font-size: 16px;
  //     }
  //   }
  // }
}
</style>
