<template>
  <div class="capital_account">
    <main>
      <div class="balance_box">
        <div class="balance">
          <div class="text">
            <span>账户余额(元)</span
            ><svg-icon
              :icon-class="eyesShow ? 'open_eyes2' : 'close_eyes'"
              @click="eyesShow = !eyesShow"
            />
          </div>
          <h3>{{ eyesShow ? (balance / 100).toFixed(2) : "*******" }}</h3>
        </div>
        <div class="balance2">
          <div class="withdrawable">
            <span class="text">可提现金额(元)</span>
            <h4>
              {{ eyesShow ? (withdrawable / 100).toFixed(2) : "*******" }}
            </h4>
          </div>
          <span class="line"></span>
          <div class="nonWithdrawable">
            <span class="text">不可提现金额(元)</span>
            <h4>
              {{ eyesShow ? (nonWithdrawable / 100).toFixed(2) : "*******" }}
            </h4>
          </div>
        </div>
        <div class="button_box">
          <!-- <van-button plain type="default" color="#C9C9C9">充值</van-button> -->
          <van-button
            type="primary"
            color="#009A4D"
            url="/#/userData/wallet/withdrawal"
            >提现</van-button
          >
          <!-- url="/#/userData/wallet/withdrawal" -->
        </div>
      </div>
      <div class="behavior">
        <van-cell
          title="提现记录"
          is-link
          value="查看"
          url="#/userData/wallet/withdrawalRecord"
        />
        <van-cell
          title="收支明细"
          is-link
          value="查看"
          url="#/userData/wallet/incomeAndExpenditureDetail"
        />
        <van-cell
          title="我的银行卡"
          is-link
          value="管理"
          url="#/userData/bankCard"
        />
        <van-cell
          title="设置交易密码"
          is-link
          url="#/userData/wallet/paymentPassword"
        />
        <!-- <van-cell title="实名认证" is-link value="已实名" /> -->
      </div>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "capital-account",
  data() {
    return {
      eyesShow: true,
      balance: 0,
      withdrawable: 0,
      nonWithdrawable: 0,
    };
  },
  computed: {
    ...mapState("user", ["electronicAcct", "bankCard", "isLogin"]),
  },
  async created() {
    if (!this.isLogin) return;
    await this.$store.dispatch("user/queryElectronicAcct");
    if (this.electronicAcct.length) this.getInfo();
  },

  methods: {
    getInfo() {
      this.electronicAcct.forEach((e) => {
        this.balance +=
          e.expensingAmount +
          e.frozenSettledAmount +
          e.pendingAmount +
          e.settledAmount +
          e.accountAmount;
        this.withdrawable += e.settledAmount + e.accountAmount;
        this.nonWithdrawable +=
          e.expensingAmount + e.frozenSettledAmount + e.pendingAmount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.capital_account {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;

  main {
    flex: 1;
    padding: 12px 15px;
    .balance_box {
      background: #fff;
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 15px 12px;
      text-align: center;
      .balance {
        padding: 48px 0;
      }
      .balance2 {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        > span {
          height: 17px;
          width: 1px;
          background: #f2f2f2;
        }
        div {
          flex: 1;
          flex-shrink: 0;
        }
      }
      .text {
        font-size: 12px;
        display: block;
        color: #666666;
        margin-bottom: 8px;
        .svg-icon {
          margin-left: 8px;
        }
      }
      h3 {
        font-family: Arial;
        font-size: 24px;
      }
      h4 {
        font-size: 17px;
        font-family: Arial;
      }
      .button_box {
        display: flex;
        .van-button {
          flex: 1;
        }
        .van-button:nth-child(2) {
          margin-left: 14px;
        }
      }
    }
    .behavior {
      border-radius: 5px;
      .van-cell:first-child {
        border-radius: 5px 5px 0 0;
      }
      .van-cell:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }
}
</style>
