<template>
  <div id="capital_account" :style="show ? 'bottom:0' : ''">
    <van-form
      @submit="onSubmit"
      :scroll-to-error="true"
      validate-trigger="onSubmit"
      ref="ruleForm"
    >
      <!-- :show-error-message="false" -->
      <div class="form">
        <h3>证件信息</h3>
        <van-field
          v-model="idCardName"
          name="idCardName"
          label="姓名"
          placeholder="请输入真实姓名"
          input-align="right"
          :rules="[{ required: true, message: '请输入真实姓名' }]"
        />
        <van-field
          v-model="idCardNumber"
          name="idCardNumber"
          label="证件号码"
          placeholder="请输入证件号码"
          input-align="right"
          :rules="Rules.idCardNumber"
        />
        <van-field
          readonly
          clickable
          name="idCardStartDate"
          :value="idCardStartDate"
          label="证件生效日期"
          placeholder="请选择"
          input-align="right"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择证件生效日期' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-datetime-picker
            @confirm="onConfirm"
            @cancel="showPicker = false"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          name="idCardStartDate"
          :value="idCardEndDate"
          label="证件失效日期"
          placeholder="请选择"
          input-align="right"
          @click="showPicker2 = true"
          :rules="[{ required: true, message: '请选择证件失效日期' }]"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-datetime-picker
            @confirm="onConfirm2"
            @cancel="showPicker2 = false"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
        <van-field name="uploader" class="van-cell-pirce2" label-width="auto">
          <template #input>
            <van-uploader
              v-model="idCardCopy"
              max-count="1"
              :after-read="afterRead"
            >
              <template #default>
                <div class="top">
                  <svg-icon icon-class="id_card_zm" />
                </div>
                <div class="bottom">
                  <img src="@/assets/images/camera_icon.png" alt="" />
                  点击上传身份证正面
                </div>
              </template>
            </van-uploader>
            <van-uploader
              v-model="idCardNational"
              max-count="1"
              :after-read="afterRead2"
            >
              <template #default>
                <div class="top">
                  <svg-icon icon-class="id_card_fm" />
                </div>
                <div class="bottom">
                  <img src="@/assets/images/camera_icon.png" alt="" />
                  点击上传身份证反面
                </div>
              </template>
            </van-uploader>
          </template>
        </van-field>
      </div>
      <div class="form">
        <h3>绑定账户信息</h3>
        <van-field
          v-model="settleAcctName"
          name="settleAcctName"
          label="账户名称"
          placeholder="请输入账户名称"
          input-align="right"
          :rules="[{ required: true, message: '请输入账户名称' }]"
        />
        <van-field
          v-model="settleAcctBankAcctNo"
          name="settleAcctBankAcctNo"
          label="银行卡号"
          placeholder="请输入银行卡号"
          input-align="right"
          label-width="auto"
          type="digit"
          :rules="Rules.settleAcctBankAcctNo"
        />
      </div>
      <div class="form">
        <h3>账户安全信息</h3>
        <van-field
          v-model="password"
          name="password"
          type="password"
          label="设置交易密码"
          placeholder="请输入交易密码"
          maxlength="6"
          input-align="right"
          :rules="Rules.password"
        />
        <van-field
          v-model="password2"
          name="password2"
          type="password"
          label="确认交易密码"
          placeholder="请再次输入交易密码"
          maxlength="6"
          input-align="right"
          :rules="[{ required: true, message: '请再次输入交易密码' }]"
        />
        <van-field
          v-model="mobileNumber"
          name="mobileNumber"
          label="交易手机号"
          placeholder="请输入交易手机号"
          input-align="right"
          type="digit"
          :rules="Rules.mobileNumber"
          maxlength="11"
        />
        <van-field
          v-model="smsCode"
          label="短信验证码"
          placeholder="请输入短信验证码"
          input-align="right"
          :rules="Rules.smsCode"
          ref="smsCode"
        >
          <template #button>
            <span style="color: #009a4d" v-show="!codeShow" @click="getCode"
              >获取验证码</span
            >
            <span v-show="codeShow">{{ codeShow }}s后再获取</span>
          </template>
        </van-field>
        <van-field name="checkbox" class="protocol_box">
          <template #input>
            <van-checkbox v-model="checkbox" icon-size="12px" />
            <p class="protocol">
              本人已阅读<a
                href="https://origmall.cn/Agreement/BankAccountServiceAgreement.pdf"
                target="_blank"
                >《正品汇用户开立银行账户服务协议》</a
              >并同意授权正品汇平台合作协议银行资金管理服务协议
            </p>
          </template>
        </van-field>
      </div>

      <div class="bottom_btn van-hairline--top" v-if="!show">
        <van-button round block type="info" native-type="submit" color="#009a4d"
          >确认提交</van-button
        >
      </div>
      <van-popup v-model="show" :close-on-click-overlay="false" class="tips">
        <svg-icon icon-class="close" @click="close" />
        <img src="@/assets/images/success.png" alt="" />
        <p>申请已提交</p>
      </van-popup>
    </van-form>
    <van-overlay :show="isLoading" class="Loading">
      <van-loading />
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDay2 } from "@/utils/validate";
import { getToken, setToken, removeToken } from "@/utils/auth";

export default {
  name: "capital-account",
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    var validatePass = (value, rule) => {
      rule.message = "请填写密码";
      if (value.length < 6) {
        rule.message = "密码最少为6位!";
        return false;
      } else if (this.password2 !== "" || value === "") {
        this.$refs.ruleForm.validateField("password2");
      }
    };
    var validatePass2 = (value, rule) => {
      rule.message = "请填写确认密码";
      if (this.password != "" && value !== this.password) {
        rule.message = "两次输入密码不一致!";
        return false;
      }
    };
    return {
      showPicker: false,
      showPicker2: false,
      minDate: new Date(1971, 0, 1),
      maxDate: new Date(2050, 10, 1),
      date: "",
      date2: "",
      codeShow: 0,
      show: false,
      isLoading: false,

      idCardIsLongTimeValidate: false, //证件照是否长期有效
      idCardName: "", //姓名
      idCardNumber: "", //身份证号码
      idCardStartDate: "", //证件开始时间
      idCardEndDate: "", //证件结束时间
      idCardCopy: [], //证件人面照
      idCardNational: [], //证件国徽照
      settleAcctName: "", //开户名称
      settleAcctBankAcctNo: "", //银行账户
      password: "", //交易授权码
      password2: "",
      mobileNumber: "", //手机号码
      smsCode: "", //短信验证码
      userId: "", //用户ID
      checkbox: false,

      Rules: {
        idCardNumber: [
          {
            required: true,
            message: "请输入证件号码",
          },
          {
            validator: (value) => {
              return /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(
                value
              );
            },
            trigger: "onBlur",
            message: "请输入正确格式的证件号码",
          },
        ],
        settleAcctBankAcctNo: [
          {
            required: true,
            message: "请输入银行卡号",
          },
          {
            validator: (value) => {
              return /^[1-9]\d{9,29}$/.test(value);
            },
            trigger: "onBlur",
            message: "请输入正确格式的银行卡号",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
            message: "请输入交易密码",
          },
        ],
        password2: [
          {
            required: true,
            validator: validatePass2,
            message: "请再次填写密码",
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: "手机号码不能为空",
            trigger: "onBlur",
          },
          {
            validator: (value) => {
              return /^(?:(?:\+|00)86)?1\d{10}$/.test(value);
            },
            message: "请输入正确格式的手机号码",
            trigger: "onBlur",
          },
        ],
        smsCode: [
          {
            required: true,
            message: "请填写验证码",
            trigger: "onBlur",
          },
        ],
      },
    };
  },
  // watch: {
  //   frontId(val, oldVal) {
  //     console.log(val[0]);
  // 		this.file(val[0])
  //   },
  // },
  created() {},
  methods: {
    onSubmit(values) {
      if (this.checkbox) {
        this.Toast({
          message: "请勾选协议",
          position: "top",
        });
      } else if (!this.idCardCopy.length) {
        this.Toast.fail("请上传身份证正面照片");
      } else if (!this.idCardNational.length) {
        this.Toast.fail("请上传身份证反面照片");
      } else {
        this.cusApplications();
      }
    },
    onConfirm(time) {
      this.idCardStartDate = formatDay2(new Date(time).getTime());
      this.showPicker = false;
    },
    onConfirm2(time) {
      this.idCardEndDate = formatDay2(new Date(time).getTime());
      this.showPicker2 = false;
    },
    //获取验证码
    getCode() {
      this.$refs.ruleForm.validate("mobileNumber").then(async () => {
        await this.$API.user
          .sendSmsCodesByMobile({
            mobileNumber: this.mobileNumber,
            serviceType: 1,
          })
          .then((res) => {
            this.Toast.success("发送成功");
            this.codeShow = 59;
            this.t = setInterval(() => {
              if (this.codeShow) {
                this.codeShow--;
              } else {
                clearInterval(this.t);
              }
            }, 1000);
          })
          .catch(() => {
            this.Toast.fail("发送失败");
          });
      });
    },
    //440204199901143033
    //6217003230057454880
    async cusApplications() {
      this.isLoading = true;
      await this.$API.user
        .cusApplications({
          object: {
            idCardCopy: this.idCardCopy[0].url,
            idCardEndDate: this.idCardEndDate,
            idCardIsLongTimeValidate: false,
            idCardName: this.idCardName,
            idCardNational: this.idCardNational[0].url,
            idCardNumber: this.idCardNumber,
            idCardStartDate: this.idCardStartDate,
            mobileNumber: this.mobileNumber,
            password: this.password,
            settleAcctBankAcctNo: this.settleAcctBankAcctNo,
            settleAcctName: this.settleAcctName,
            smsCode: this.smsCode,
            userId: this.userInfo.id,
            serviceType: 1, //服务商类型|1：广银|2：江苏银行

            // "cusId": "string", //Cus ID
            // "settleAcctBankBranchCode": "string", //开户银行联行号
            // "settleAcctBankBranchName": "string", //开户支行名称
            // "settleAcctBankCode": "string", //开户银行编码
            // "settleAcctBankName": "string", //开户银行名称
          },
        })
        .then((res) => {
          if (res.data.data.status === "failed") {
            this.isLoading = false;
            this.Toast({
              message: res.data.data.issue.exceptionMsg,
              type: "fail",
              duration: 0,
              // forbidClick: true,
              closeOnClickOverlay: true,
              overlay: true,
              closeOnClick: true,
            });
          } else {
            this.isLoading = false;
            this.show = true;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          // this.Toast.fali(err);
          console.log(err);
        });
    },

    afterRead(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.idCardCopy[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.Toast.fail("图片上传失败");
        });
      // axios
      //   .post("https://upload.realbrand.net/image/imageUpload", formdata)
      //   .then((res) => {
      //     console.log(res.data.data);
      //     this.frontId[0].url = res.data.data;
      //     console.log(this.frontId);
      //   });
    },
    afterRead2(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.idCardNational[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.Toast.fail("图片上传失败");
        });
    },
    close() {
      if (this.$route.query.barcode) {
        this.$router.replace({
          path: "goodsDetails",
          query: {
            barcode: this.$route.query.barcode,
            enterpriseId: this.$route.query.enterpriseId,
          },
        });
      } else if (this.$route.query.PDA) {
        removeToken();
        window.close();
      } else {
        this.$router.push("/home");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#capital_account {
  padding-top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 50px;
  overflow-y: scroll;
  h3 {
    font-size: 14px;
  }
  .van-form {
    background: #f5f5f5;
    overflow: hidden;
    .form {
      background: #fff;
      padding: 18px 15px;
      margin-top: 10px;
      padding-bottom: 0;
    }
    .tips {
      padding: 34px 0 24px 0;
      width: 242px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 48px;
      }
      p {
        font-size: 18px;
        color: #333333;
        line-height: 23px;
        margin-top: 10px;
      }
      .svg-icon {
        position: absolute;
        top: 14px;
        right: 12px;
        cursor: pointer;
      }
    }
    .protocol_box {
      line-height: 18px;
      .van-field__control {
        align-items: flex-start;
      }
      .van-checkbox {
        overflow: inherit;
        // margin-top: 2px;
        margin-right: 4px;
      }
    }
    .protocol_box:after {
      border: none;
    }
  }
  .bottom_btn {
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    padding: 3px 15px;
  }
  ::v-deep .van-cell {
    padding: 15px 0;
    .van-cell__title {
      color: #333;
    }
    .van-cell__value {
      .van-field__error-message {
        text-align: end;
      }
    }
    // .van-radio__icon {
    //   padding-top: 1px;
    //   .van-icon {
    //     width: 18px;
    //     height: 18px;
    //   }
    // }
    // .van-radio__icon--checked {
    //   position: relative;
    //   .van-icon {
    //     border-color: #009a4d;
    //     background: #fff;
    //   }
    //   .van-icon-success:before {
    //     content: "";
    //     width: 10px;
    //     height: 10px;
    //     border-radius: 50%;
    //     background: #009a4d;
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     margin-top: -5px;
    //     margin-left: -5px;
    //   }
    // }
  }
  .van-cell-pirce,
  .van-cell-pirce2 {
    display: block;
    padding: 25px 0;
    .van-uploader {
      flex: 1;
    }
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }
  .address {
    padding: 15px;
  }
  .Loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss">
.van-cell-pirce2 {
  .van-field__control {
    .van-uploader:nth-child(1) {
      padding-right: 7.5px;
    }
    .van-uploader:nth-child(2) {
      padding-left: 7.5px;
    }
    .van-uploader__wrapper {
      display: block;
    }
    .van-uploader {
      .top {
        padding: 10px 0;
        font-size: 70px;
      }
    }
  }
}
.van-cell-pirce {
  .van-uploader__wrapper {
    padding: 0 18px;
  }
  .van-uploader__input-wrapper {
    flex: 1;
    .top {
      font-size: 72px;
      padding: 50px 0;
    }
  }
}
.van-cell-pirce,
.van-cell-pirce2 {
  .van-uploader__preview {
    flex: 1;
    margin: 0;
    .van-image {
      width: auto;
      height: auto;
    }
  }
  .van-cell__value {
    .van-uploader {
      .top {
        border-radius: 5px 5px 0 0;
        border: 1px dashed #d3d3d3;
        border-bottom: none;
        text-align: center;
      }
      .bottom {
        line-height: 20px;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 0;
        border-radius: 0 0 5px 5px;
        background: #3e93fe;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 16px;
          margin-right: 4px;
        }
      }
    }
  }
}

.van-cell-bank-card {
  .van-field__control {
    justify-content: space-between;
    p:nth-child(1) {
      color: #999999;
      span {
        color: #f61423;
      }
    }
    // p:nth-child(2) {
    // }
  }
}
</style>
